/* src/styles.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-tab {
   @apply p-2 md:px-4 rounded-md border mx-2 font-thin border-gray-400  bg-white hover:shadow-lg  hover:shadow-slate-300
}

.btn-selected {
 @apply bg-green-800 text-white
  /* Add any other styles you want for the selected button */
}

.card-drill {
   @apply p-2 py-4 sm:p-4  md:px-8 md:p-12 min-h-56 md:min-h-80  rounded-md mt-6 md:mt-8 bg-white
}


.hd-drill {
    @apply text-[20px] md:text-[30px] font-thin italic
}

.sub-hd {
    @apply font-thin text-[14px] md:text-[20px]
}

.digit {
  @apply  text-[15px] md:text-[30px] font-semibold  text-gray-500 text-center
}

.digit-decimal {
  @apply  text-[13px] md:text-[20px] font-semibold  text-gray-500 text-center
}
.line-tr {
    @apply flex items-center mt-2 mb-2 md:mt-4 md:mb-4
}
.line {
    @apply border-t border md:border-2  border-gray-500 w-4  md:w-6 mx-auto
}

.line-input {
    @apply border-t border md:border-2  border-gray-500 w-8 md:w-14 mx-auto

}

.solution-digit {
  @apply text-[10px] md:text-[15px] font-semibold  text-gray-500 text-center mt-4
}

.digit-input {
    @apply text-[14px]  md:text-[25px] font-semibold  text-gray-500 text-center
  }
input::placeholder{
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  transform: translateY(-20%);
  z-index: 100;
  
}


.input {
    @apply w-10 md:w-20 z-50  text-center h-5 md:h-8  bg-transparent border border-gray-400 rounded-[5px] 
}


.input-decimal {
  @apply w-6 md:w-12 z-50  text-center h-5 md:h-8  bg-transparent border border-gray-400 rounded-[3px] 
}

.input-div {
  @apply w-8 text-center h-5 md:h-8  bg-transparent border border-gray-400 rounded-[5px] 
}

.btn-drill {
  @apply px-8 p-1 md:px-20 md:p-2 font-inter md:font-semibold text-[12px] md:text-[15px] text-blue-600 rounded-[3px] md:rounded-[5px] hover:bg-blue-600 hover:text-white focus:bg-blue-600 focus:text-white  border md:border-2   border-blue-600
}

.tr-different-deno {
 @apply flex  items-center md:mr-3 px-2 md:px-0
}

.btn-random {
  @apply font-inter w-[40%] sm:w-[50%] md:w-[40%] md:w-[40%] rounded-[3px] md:rounded-[5px] py-1 border text-[10px] sm:text-[12px] md:text-[18px]
}
